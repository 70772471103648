import React from 'react';
import "./Footer.scss";
import {Link, useNavigate} from "react-router-dom";
import telegram from "../../img/footerTelegram.svg";
import vk from "../../img/footerVk.svg";
import {KEEPING_ROUTE, PRIVACY_ROUTE, RETURN_ROUTE, RULES_ROUTE} from "../../utils/consts";

function Footer() {
	const navigate = useNavigate();
	function scrollTop (){
		window.scrollTo(0,0);
	}

	const goToContact = () => {
		navigate("/contacts");
		window.scrollTo(0,0);
	}
	return (
		<div className="footer">
			<div className="container">
				<div className="footer-wrapper">
					<div className="footer-logo">
						<p>RED-ROSE &copy;{new Date().getFullYear()}</p>
						<p>Все права защищены</p>
						<div className="footer-links">
							<Link to = {PRIVACY_ROUTE} onClick={scrollTop}>Условия оферты</Link>
							{/*<Link to = {RULES_ROUTE} onClick={scrollTop}>Оферта</Link>*/}
							{/*<Link to = {KEEPING_ROUTE} onClick={scrollTop}>Хранение</Link>*/}
							{/*<Link to = {RETURN_ROUTE} onClick={scrollTop}>Возврат</Link>*/}
						</div>
					</div>
					<div className="footer-network">
						<button onClick={goToContact}>Заказать</button>
						<div className="networks">
							<a href="#" target="_blank" rel="noopener"><img src={telegram} alt="img"/></a>
							<a href="#" target="_blank" rel="noopener"><img src={vk} alt="img"/></a>
						</div>
						<a className="network-mail" href="mailto:info@red-rose.ru">info@red-rose.ru</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Footer;