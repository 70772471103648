import React from 'react';
import "./Settings.scss";
import TypeBar from "../TypeBar/TypeBar";
import IsValidBar from "../IsValidBar/IsValidBar";

function Settings(props) {
	return (
		<div className="settings">

			<TypeBar/>
			<IsValidBar/>
		</div>
	);
}

export default Settings;