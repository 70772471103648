import React, {useContext, useEffect, useState} from "react";
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter/AppRouter";
import Navigation from "./components/Navigation/Navigation";
import Footer from "./components/Footer/Footer";

function App() {
  return (
      <BrowserRouter>
          <Navigation />
          <AppRouter />
          <Footer/>
      </BrowserRouter>
  );
}

export default App;
