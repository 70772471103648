import React, {useContext} from 'react';
import "./IsValidBar.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

function IsValidBar(props) {
	const {item} = useContext(Context);
	const handleIsValidToggle = (isValidId) => {
		const selectedIsValid = item.isValid.find(isValid => isValid.id === isValidId);
		item.setSelectedIsValid(selectedIsValid);
	};

	return (
		// доделать логику с темблером
		<div className="__wrapper">
			<h2>Наличие:</h2>
			<div className="is_valid_bar">
				<p>Все</p>
				<label key={item.isValid.id} className="switch">
					<input
						type="radio"
						value={item.isValid.id}
						checked={item.selectedIsValid.id === item.isValid.id}
						onChange={() => handleIsValidToggle(item.isValid.id)}
					/>
					<span className="slider round"></span>
					{item.isValid.name}
				</label>
				<p>В наличии</p>
			</div>
		</div>
	);
}

export default observer(IsValidBar);