export const ADMIN_ROUTE = "/admin";
export const LOGIN_ROUTE = "/login";
export const REGISTRATION_ROUTE = "/registration";
export const SHOP_ROUTE = "/"
export const BASKET_ROUTE = "/basket";
export const ITEM_ROUTE = "/item";
export const CONTACTS_ROUTE = "/contacts";
export const ABOUT_ROUTE = "/about";
export const PRIVACY_ROUTE = "/privacy";
export const RULES_ROUTE = "/rules";
export const KEEPING_ROUTE = "/keeping";
export const RETURN_ROUTE = "/return";
