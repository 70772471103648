import React, {useContext, useState} from 'react';
import "./Auth.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import eyeShow from "../../img/eyeShow.svg";
import eyeHide from "../../img/eyeHide.svg";
import {useNavigate} from "react-router-dom";

function Auth() {
	const {user} = useContext(Context);
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("")
	const [isVisiblePassword, setIsVisiblePassword] = useState(false);
	const [isVisibleConfirmPassword, setIsVisibleConfirmPassword] = useState(false);
	const [isPrivacyPolicyAgreed, setIsPrivacyPolicyAgreed] = useState(false);
	const [isPrivacyPolicyValid, setIsPrivacyPolicyValid] = useState(true);
	const [identicalError, setIdenticalError] = useState(false);

	const togglePasswordVisibility = () => {
		setIsVisiblePassword(!isVisiblePassword);
	};
	const toggleConfirmPasswordVisibility = () => {
		setIsVisibleConfirmPassword(!isVisibleConfirmPassword);
	};

	const handlePrivacyPolicyChange = (event) => {
		setIsPrivacyPolicyAgreed(event.target.checked);
		setIsPrivacyPolicyValid(true);
	};

	const emailHandler = (e) =>{
		e.preventDefault();
		setEmail(e.target.value)
	}

	const passwordHandler = (e) =>{
		e.preventDefault();
		setPassword(e.target.value)
	}

	const passwordConfirmHandler = (e) =>{
		e.preventDefault();
		setConfirmPassword(e.target.value)
	}

	{/*const handleLogin = async () => {*/}
	{/*	if (!isPrivacyAgreed) {*/}
	// 		setPrivacyPolicyValid(false);
	// 		return;
	// 	}
	{/*	try {*/}
	// 		await store.login(email, password);
	// 		if (!Object.keys(store.errors).length) {
	// 			navigate('/profile');
	// 		}
	// 	} catch (error) {
	// 		console.error('Ошибка при входе:', error);
	// 	}
	// };


	return (
		<div className="auth">
			<div className="__input_group">
				<input
					onChange={(e) => emailHandler(e)}
					value={email}
					type="text"
					// className={store.errors.email ? 'invalid-input' : ''}
					placeholder="Email"
					autoComplete="email"
				/>
				{/*{store.errors.email && <div className="error-message">{store.errors.email}</div>}*/}
				<div className="password-container">
					<input
						type={isVisiblePassword ? 'text' : 'password'}
						value={password}
						onChange={(e) => passwordHandler(e)}
						placeholder="Пароль"
						// className={store.errors.password || identicalError ? 'invalid-input' : ''}
						autoComplete="current-password"
					/>
					{isVisiblePassword ? (
						<img onClick={togglePasswordVisibility} src={eyeShow} alt="img" />
					) : (
						<img onClick={togglePasswordVisibility} src={eyeHide} alt="img" />
					)}
				</div>
				<div className="password-container">
					<input
						type={isVisibleConfirmPassword ? 'text' : 'password'}
						value={confirmPassword}
						onChange={(e) => passwordConfirmHandler(e)}
						placeholder="Повторите пароль"
						// className={store.errors.password || identicalError ? 'invalid-input' : ''}
						autoComplete="current-password"
					/>
					{isVisibleConfirmPassword ? (
						<img onClick={toggleConfirmPasswordVisibility} src={eyeShow} alt="img" />
					) : (
						<img onClick={toggleConfirmPasswordVisibility} src={eyeHide} alt="img" />
					)}
				</div>

				{/*{identicalError && <div className="error-message">Пароли не совпадают</div>}*/}
				{/*{store.errors.password && <div className="error-message">{store.errors.password}</div>}*/}
				{/*{store.errors.general && <div className="error-message">{store.errors.general}</div>}*/}

				<button className="__btn">Войти</button>
			</div>

		</div>
	);
}

export default observer(Auth);