import React, {useContext} from 'react';
import "./Item.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import Card from "../Card/Card";

function Item() {
	const {item} = useContext(Context);
	return (
		<div className="items">
			{
				item.flowers.map(item => (
					<Card item={item} key={item.id}/>
				))
			}
		</div>
	);
}

export default observer(Item);