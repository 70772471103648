import React from 'react';
import "./Main.scss";
import Settings from "../../components/Settings/Settings";
import Item from "../../components/Item/Item";

function Main(props) {
	return (
		<div className="main">
			<div className="container-top">
				<div className="container">
					<div className="main_wrapper">
						<Settings/>
						<Item/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Main;