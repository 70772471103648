import React from 'react';
import "./InfoPages.scss";

function Privacy() {
	return (
		<div className="info_page">
			<div className="container-top">
				<div className="container">
					<h1>В настоящей публичной Оферте нижеприведенные термины используются в следующем значении:</h1>
					<ul className="privacy">
						<li className="title">Термины и определения</li>
						<ul>
							<li>1.1. «Покупатель» - любое лицо (гражданин, индивидуальный предприниматель либо юридическое лицо), которое оформляя заказ (именуемый в дальнейшем «Заказ») на приобретение и доставку «Цветов», информация о которых размещена на «Интернет-сайте», принимает (акцептует) настоящее публичное предложение (оферту) Продавца о заключении Договора розничной купли-продажи.</li>
							<li>1.2. «Акцепт Оферты» – полное и безоговорочное принятие Оферты Покупателем, путем оформления Заказа на «Интернет-сайте» и его оплаты.</li>
							<li>1.3. «Интернет-сайт» - Интернет-магазин <a href="https://red-rose.studio">red-rose.studio</a> .</li>
							<li>1.4. «Договор» - Договор розничной купли-продажи Цветов, ,букетов, подарочных корзин.</li>
							<li>1.5. «Товар» - оформленная в соответствии с требованиями Покупателя, с использованием
								профессиональных флористических навыков, совокупность цветов и других растений, специальной
								упаковки (включая корзины, кашпо, ленты и др.), фрукты, сладости, декор, мягкие игрушки,
								открытки и иные носители текста, а также дополнительные подарки, которые представлены на
								Интернет-сайте.
							</li>
							<li>1.6. «Получатель» - указанные Покупателем лица, принимающие доставленные Цветы, Корзины с
								подарками, мягкие игрушки.
							</li>
							<li>1.7. «Заказ» - оформляемая на Интернет-сайте Продавца заявка Покупателя на покупку и доставку Товара, который содержит согласованную обеими Сторонами информацию о количественных и качественных характеристиках Товара, а также о времени и месте их доставки. Перечень информации, подлежащей согласованию Сторонами Договора при оформлении Заказа, определяется п. 2 Оферты. Покупатель может оформить Заказ на сайте Интернет-магазина либо через оператора по телефонам, указанным на сайте, на условиях настоящей публичной оферты. Покупатель, оформляя Заказ на приобретение и доставку Товаров, информация о которых размещена на Интернет-сайте, принимает (акцептует) настоящее публичное предложение (оферту) Продавца о заключении Договора розничной купли-продажи. Предложение о заключении Договора розничной купли-продажи действует в отношении любых Цветов(и др. Товаров) (кроме сезонных) в течение всего периода времени, пока информация о соответствующих Цветах размещена на Интернет-сайте.</li>
							<li>С момента подтверждения Заказа на приобретение и доставку Товара, оформленного в соответствии с размещенными на Интернет-сайте правилами, между Продавцом и Покупателем, заключен Договор розничной купли-продажи о нижеследующем:</li>
						</ul>
						<li className="title">Предмет Договора</li>
						<ul>
							<li>2.1. Продавец обязуется заключить Договор в соответствии с действующим прейскурантом и
								каталогами, опубликованными на Интернет-сайте, и организовать доставку товара Получателям, а
								Покупатель обязуется оплатить Товар на условиях настоящего Договора. Количество и наименование
								Товара определяются Заказом Покупателя, оформленным в соответствии с размещенными на
								Интернет-сайте правилами.
							</li>
							<li>2.2. Общая сумма заказа Покупателя (именуемая в дальнейшем «Сумма Заказа»), включает в себя
								покупную цену Товаров, открыток и других сопутствующих товаров, а так же стоимость доставки
								указанным Покупателем лицам.
							</li>
							<li>2.3. В случае указания Покупателем при оформлении Заказа отдаленных районов доставки или редких
								видов (сезонных) Цветов окончательная цена и иные условия договора согласуется по телефону или
								электронной почте.
							</li>
							<li>2.4. Товары подлежат доставке Получателю по адресу, указанному в Заказе. Срок исполнения заказа
								Покупателя зависит от вида Товаров (букеты и цветочные композиции, свадебные букеты,
								эксклюзивные корзины), условий доставки (доставка в рабочее время, доставка в праздники и
								предпраздничные дни, доставка в нерабочее время), от места доставки, зоны обслуживания.
								Подробное описание сроков доставки Товаров Получателям представлено в разделе «Доставка».
							</li>
							<li>2.5. При регистрации (размещении) Заказа на Интернет-сайте Продавца Покупатель обязуется
								предоставить следующую регистрационную информацию о себе и Получателе Товара:
								- фамилия, имя (на русском языке), город, страна, адрес электронной почты, контактные телефоны;
								- фамилия, имя (на русском языке) Получателя Товара, фактический адрес доставки, контактные
								телефоны, пожелания по доставке.
							</li>
							<li>2.6. Продавец не несет ответственности за содержание и достоверность информации, предоставленной
								Покупателем при оформлении Заказа, в том числе за указанные им персональные данные.
							</li>
							<li>2.7. Продавец вправе привлекать для исполнения Заказов третьих лиц, оставаясь при этом
								ответственным за их действия перед Покупателем и Получателем.
							</li>
							<li>2.8. Отдельно обращаем ваше внимание на то, что оформлять заказ желательно заранее – не меньше чем за сутки до даты вручения. При этом если вам нужна срочная доставка, об этом нужно сообщать при оформлении заказа, и тогда мы выполним ее в день обращения.  Кроме того, перед оформлением заказа рекомендуем вам связаться с нашим менеджером, который ответит на все Ваши вопросы, а также проконсультирует о наличии Товара.
							</li>
							<li>2.9. Работа в праздничные дни: 6,7,8 марта, 13,14 февраля, день Матери. Доставку к указанному  времени мы не выполняем, что связано с большим количеством заявок. В эти дни мы осуществляем доставку в течение дня.</li>
							<ul>
								<li>2.9.1. В праздничные дни: 6,7,8 марта, 13,14 февраля, День Матери, в исключительных случаях, замена цвета роз в букете может осуществляться без согласования с клиентом для осуществления своевременной доставки.</li>
							</ul>
							<li>2.10. При оформлении заказа в Интернет-магазина, Покупатель дает согласие на обработку своих персональных данных (в том числе фамилию, имя, отчество, адрес субъекта персональных данных) в соответствии со ст. 3, ст. 9, ст. 15 ФЗ «О персональных данных» от 27.07.2006 г. в целях рассылки каталогов, информации об акциях и спепредложениях. Согласие Покупателя на обработку персональных данных предоставляется без ограничения срока его действия.</li>
							<li>2.11. Покупатель, указавший свой номер мобильного телефона при оформлении заказа в Интернет-магазине, указанным действием дает согласие на получение им по такому номеру мобильного телефона смс-рассылок рекламного и информационного содержания.</li>
						</ul>
						<li className="title">Права и обязанности Сторон</li>
						<ul>
							<li>3.1. Продавец обязан:</li>
							<ul>
								<li>
									3.1.1. Организовать с привлечением третьих лиц доставку и передачу Товара Получателю
									согласно принятому к исполнению Заказу.
								</li>
								<li>3.1.2. В случае если заказана услуга «фотография доставки подарка», выслать фотографию на электронный адрес, указанный при оформлении Заказа, в течение 3 (трех) рабочих дней с момента доставки Товара. Продавец освобождается от обязательств по услуге «фотография доставки подарка» в случае отказа Получателя от фотосъемки.</li>
								<li>3.1.3. В случае ненадлежащего качества Товара совершить действия, обеспечивающие удовлетворение требований Покупателя, связанных с ненадлежащим качеством Товара: возвратить всю или часть суммы денежных средств Покупателю, уплаченных им за Товар, либо организовать повторную доставку Получателю Товара надлежащего качества. После предоставления фотографий Покупателем о несоответствии качества товара.</li>
								<li>3.1.4. После передачи Товара Получателю информировать Покупателя об окончании выполнения его Заказа посредством sms-сообщения или звонка на телефонный номер либо на адрес электронной почты, указанные Покупателем при размещении Заказа. В случае если в течение 3 (трех) дней после направления Продавцом Покупателю уведомления об окончании выполнения Заказа, от Покупателя не поступит возражений, обязательства Продавца по настоящему Договору считаются выполненными.</li>
								<li>3.1.5. В случае отказа Получателя от принятия Товара или его отсутствия по месту доставки в согласованное в заказе время, лицо, осуществляющее доставку Товара обязано принять Товар на ответственное хранение с уведомлением Покупателя по телефону или электронной почте, указанным при оформления Заказа. Принятые на ответственное хранение Товары Покупатель в течение суток с момента принятия их на хранение обязан самостоятельно получить в указанные Продавцом время и месте, если иной срок не будет согласован Сторонами, либо согласовать новое время доставки при условии оплаты повторной доставки в размере 400 руб. в пределах города. По истечении указанного срока, Договор считается исполненным с удержанием 50 % (Пятьдесят процентов) от стоимости Товара.</li>
							</ul>
							<li>3.2. Продавец вправе:</li>
							<ul>
								<li>
									3.2.1. Удержать полную стоимость Заказа в случае указания Покупателем
									несуществующего или неполного адреса Получателя, за исключением случаев, когда, по
									соглашению Сторон, Покупатель оплачивает повторную доставку (в размере 400 руб. в
									пределах города) Товара по уточненному адресу.
								</li>
								<li>3.2.2. Осуществить доставку Товара по адресу, отличному от указанного в Заказе
									Покупателя, при условии согласования нового адреса и времени доставки с Получателем.
								</li>
								<li>3.2.3. Отказаться от доставки без возврата уплаченной Суммы Заказа, либо по соглашению Сторон получить от Покупателя дополнительную оплату стоимости доставки либо стоимости Товара, в случае указания Покупателем неверного населенного пункта Получателя.
								</li>
							</ul>
							<li>3.3. Покупатель обязан:</li>
							<ul>
								<li>3.3.1. Произвести оплату Суммы Заказа до момента доставки Товара Получателю в
									соответствии с условиями оформленного Покупателем Заказа. Оплату заказанных Товаров
									Покупатель вправе производить одним из следующих способов, описанных в разделе
									«Оплата заказа».
								</li>
								<li>3.3.2. Возмещать Продавцу любые дополнительные расходы, возникшие по вине Покупателя до исполнения обязательств Продавцом.</li>
							</ul>
						</ul>
						<li className="title">Дополнительные условия</li>
						<ul>
							<li>4.1. Настоящий Договор вступает в силу с момента получения Продавцом Заказа Покупателя и
								действует до полного исполнения Сторонами обязательств, предусмотренных Договором и
								вытекающих из него.
							</li>
							<li>4.2. При размещении Заказа Покупатель удостоверяет, что условия настоящего Договора и
								прочие условия указанные в Интернет-сайте по адресу <a href="https://">red-rose.studio</a>. На момент
								заключения Договора принимаются им без каких-либо возражений и соответствуют его
								действительной воле как Покупателя. При размещении Заказа Покупатель уяснил значение
								используемых в настоящем Договоре терминов, слов и выражений согласно их
								нормативно-правовому определению и (или) толкованию в соответствии с законодательством
								Российской Федерации.
							</li>
							<li>4.3. Какие сложности могут возникнуть при доставке подарков?
								Из наиболее распространенных проблем, которые могут возникнуть в процессе доставки товаров, можно назвать следующие:
								<ul>
									<li>•	Получатель на время отлучился по своим делам. В этом случае курьер с ним созванивается, выясняет, через какое время он прибудет по указанному адресу, дожидается его и вручает подарок.</li>
									<li>•	Домофон в доме, где проживает получатель, не работает</li>
									<li>•	Курьеру не открывают дверь.</li>
								</ul>
								При возникновении затруднений Исполнитель  связывается  с Покупателем, и он называет время и адрес, когда и куда ему удобнее доставить букет товар. Повторная доставка оплачивается дополнительно в размере 400 руб. в пределах города (см. п.3.1.5.)
							</li>
						</ul>
						<li className="title">Составление букета и замена цветов</li>
						<ul>
							<li>5.1. Букет (корзина), который Покупатель  выбрали в нашем каталоге, будет изготовлен  флористами максимально похожим на фото с сайта. Но хотим обратить внимание, что все букеты (корзины) изготавливаются в ручную флористами, поэтому возможно незначительное отклонение по форме от фото, представленного на сайте.</li>
							<li>5.2. Если же на складе не оказывается нужных цветов, фруктов, и др. составляющих что
								случается очень редко, наш менеджер связывается с Покупателем по телефону, указанному в
								заявке и предлагает варианты замены товара.
							</li>
							<li>5.3. В том случае, если нам не удается связаться Покупателем по телефону и электронной
								почте, наш флорист составляет букет с заменой недостающих товаров на равноценные им по
								качеству и стоимости. При этом, выбранная вами цветовая гамма полностью сохраняется. Это
								позволяет нам вручить подарок без задержек.
							</li>
							<li>5.4. Если заказ срочный – «день в день», и в наличии на складе не имеется каких-либо
								составляющих, а доставка нужна незамедлительно, наши флористы подбирают аналогичные,
								максимально похожие товары и составляют букет(корзину) из них. В этом случае стоимость
								Товара остается прежней, и он имеет максимально схожий с оригиналом дизайн.
							</li>
							<li>5.5. Если Покупатель при оформлении заказа не указал желаемый цвет роз, тюльпанов и любых моно композиций, а связаться с ним не удается по каким-либо причинам, то в целях вовремя исполненного заказа букет собирается в цветовой гамме выбранной флористом.
							</li>
						</ul>
					</ul>
					<h1>ВОЗВРАТ И ОБМЕН СРЕЗАННЫХ ЦВЕТОВ И ГОРШЕЧНЫХ РАСТЕНИЙ</h1>
					<li>Цветы являются живым товаром. К отношениям между Покупателем и Продавцом применяется право Российской Федерации.
						В соответствии с Законом Российской Федерации «О защите прав потребителей» от 07.02.1992 № 2300-1 (в ред. от 25.10.2007г.) и Постановлением Правительства Российской Федерации от 19.01.1998 № 55 (в ред. 27.03.2007г.) срезанные цветы и горшечные растения обмену и возврату не подлежат (указаны в Перечне непродовольственных товаров надлежащего качества, не подлежащих возврату или обмену).
						Покупатель Интернет-магазина имеет право отказаться от получения товара в момент доставки, если доставлен товар ненадлежащего качества (на основании п.3 ст. 497 ГК РФ, статья 21 Закона "О защите прав потребителей").
						При оплате товара банковской картой возврат денежных средств  возможен только на карту, с которой была совершена операция, после электронной оплаты оформить возврат наличными нельзя. Сроки зачисления могут варьировать от 3 до 40 рабочих дней, в зависимости от Банка.
					</li>
				</div>
			</div>
		</div>

	);
}

export default Privacy;