import React from 'react';
import "./About.scss";

function About(props) {
	return (
		<div className="about">

		</div>
	);
}

export default About;