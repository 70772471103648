import {
	ABOUT_ROUTE,
	ADMIN_ROUTE,
	BASKET_ROUTE, CONTACTS_ROUTE,
	ITEM_ROUTE, KEEPING_ROUTE,
	LOGIN_ROUTE, PRIVACY_ROUTE,
	REGISTRATION_ROUTE, RETURN_ROUTE, RULES_ROUTE,
	SHOP_ROUTE
} from "./utils/consts";
import Admin from "./pages/Admin/Admin";
import About from "./pages/About/About";
import Contacts from "./pages/Contacts/Contacts";
import Basket from "./pages/Basket/Basket";
import Main from "./pages/Main/Main";
import Auth from "./pages/Auth/Auth";
import CardItem from "./components/CardItem/CardItem";
import Privacy from "./pages/InfoPages/Privacy";
import Rules from "./pages/InfoPages/Rules";
import Return from "./pages/InfoPages/Return";
import Keeping from "./pages/InfoPages/Keeping";


export const authRoutes = [
	{
		path: ADMIN_ROUTE,
		component: Admin
	},

];

export const publicRoutes = [
	{
		path: LOGIN_ROUTE,
		component: Auth
	},
	{
		path: REGISTRATION_ROUTE,
		component: Auth
	},
	{
		path: SHOP_ROUTE,
		component: Main
	},
	{
		path: BASKET_ROUTE,
		component: Basket
	},
	{
		path: `${ITEM_ROUTE}/:id`,
		component: CardItem
	},
	{
		path: CONTACTS_ROUTE,
		component: Contacts
	},
	{
		path: ABOUT_ROUTE,
		component: About
	},
	{
		path: PRIVACY_ROUTE,
		component: Privacy
	},
	{
		path: RULES_ROUTE,
		component: Rules
	},
	{
		path: KEEPING_ROUTE,
		component: Keeping
	},
	{
		path: RETURN_ROUTE,
		component: Return
	},
];