import React, {useContext} from 'react';
import "./TypeBar.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

function TypeBar(props) {
	const {item} = useContext(Context);
	return (
		<div className="type_bar">
			<h2>Коллекция:</h2>
			<ul>
				{item.types.map(type => (
					<li
						className={type.id === item.selectedType.id ? "active" : ""}
						onClick={() => item.setSelectedType(type)}
						key={type.id}>
						{type.name}
					</li>
				))}
			</ul>
		</div>
	);
}

export default observer(TypeBar);