import React, { useContext, useEffect, useState } from 'react';
import "./CardItem.scss";
import Accordion from "../Accordion/Accordion";
import cart from "../../img/cardBlack.svg";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";

function CardItem(props) {
	const { item } = useContext(Context);
	const [data, setData] = useState(null);
	const { id } = useParams();

	useEffect(() => {
		const jsonData = Array.from(item._flowers);
		const filteredData = jsonData.find(elem => elem.id === parseFloat(id));
		setData(filteredData);
	}, [id, item._flowers]);

	return (
		<div className="card_item">
			<div className="container-top">
				<div className="container">
					<div className="__wrapper">
						{data && (
							<div className="__image">
								<img src={data.img} alt="img" />
							</div>
						)}
						{data && (
							<div className="__description">
								<h3>{data.price} &#8381;</h3>
								<span>{data.name}</span>
								<button>
									<img src={cart} alt="img" />
									Добавить в корзину
								</button>
								<Accordion item={data} type="." />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default observer(CardItem);