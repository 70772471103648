import {makeAutoObservable} from "mobx";

export default class ItemStore {
	constructor() {
		this._types = [
			{id: 1, name: "Букеты"},
			{id:2, name: "Свадебные"},
		];
		this._isValid = [
			{id: 1, name: "qwe"},
			{id: 2, name: "asd"}
		];
		this._flowers = [
			{id: 1, name: "Грация", text: "", price: 1000, img: "https://content3.flowwow-images.com/data/flowers/524x524/10/1683705906_47710010.jpg"},
			{id: 2, name: "Корзина орхидея", text: "", price: 1500, img: "https://content3.flowwow-images.com/data/flowers/524x524/40/1683888795_26955140.jpg"},
			{id: 3, name: "Сирень", text: "", price: 900, img: "https://content3.flowwow-images.com/data/flowers/524x524/76/1715155512_72216276.jpg"},
			{id: 4, name: "Белые облака", text: "", price: 1100, img: "https://content3.flowwow-images.com/data/flowers/524x524/37/1619853160_99672237.jpg"},
			{id: 6, name: "Белоснежные розы", text: "", price: 5000, img: "https://content3.flowwow-images.com/data/flowers/524x524/32/1693983821_32103932.jpg"},
			{id: 5, name: "25 Роз", text: "", price: 2500, img: " https://content3.flowwow-images.com/data/flowers/524x524/57/1668502168_48808657.jpg"},
			{id: 7, name: "Пышный бал", text: "", price: 3500, img: "https://content2.flowwow-images.com/data/flowers/524x524/69/1683706122_44828769.jpg"},
			{id: 8, name: "Пионовидные розы", text: "", price: 4500, img: "https://content3.flowwow-images.com/data/flowers/524x524/63/1698907156_80873163.jpg"},
			{id: 9, name: "Пылающий вихрь", text: "", price: 3200, img: "https://content2.flowwow-images.com/data/flowers/524x524/32/1709762029_35385732.jpg"},
			{id: 10, name: "Розовый фламинго", text: "", price: 2100, img: "https://content2.flowwow-images.com/data/flowers/524x524/70/1619853709_41892170.jpg"},
		];

		this._selectedType = {};
		this._selectedIsValid = {};
		makeAutoObservable(this);
	}

	setTypes(types) {
		this._types = types;
	}
	setIsValid(bool) {
		this._isValid = bool;
	}

	setFlower(flowers) {
		this._flowers = flowers;
	}

	setSelectedType(type){
		this._selectedType = type;
	}
	setSelectedIsValid(isValid){
		this._selectedIsValid = isValid;
	}

	get types() {
		return this._types;
	}
	get isValid() {
		return this._isValid;
	}
	get flowers() {
		return this._flowers;
	}
	get selectedType(){
		return this._selectedType;
	}
	get selectedIsValid(){
		return this._selectedIsValid;
	}
}