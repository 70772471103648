import React from 'react';
import "./Card.scss";
import {ITEM_ROUTE} from "../../utils/consts";
import {useNavigate} from "react-router-dom";
import cartImg from "../../img/cardBlack.svg";



function Card(props) {
	const navigate = useNavigate();

	return (
		<div className="__item" key={props.item.id} >
			<div className="__image" onClick={() => {
				navigate(ITEM_ROUTE + "/" + props.item.id)
				window.scrollTo(0, 0)
			}}>
				<img src={props.item.img} alt="img"/>
			</div>
			<div className="__body">
				<div className="__info">
					<h3>{props.item.name}</h3>
					<span>{props.item.price} &#8381;</span>
				</div>
				<div className="__cart">
					<img src={cartImg} alt="img"/>
				</div>
			</div>

		</div>
	);
}

export default Card;