import React from 'react';
import "./Accordion.scss";

function Accordion(props) {
	return (
		<div className="accordion-wrapper">
			<div className="accordion">

				<div className="accordion-item">
					<input className="accordion-input" type="checkbox" name="item" id="item-1"/>
					<label className="accordion-title" htmlFor="item-1">Описание</label>
					<div className="accordion-text">
						<p>{props.item.text}</p>
					</div>
				</div>
				<div className="accordion-item">
					<input className="accordion-input" type="checkbox" name="item" id="item-2"/>
					<label className="accordion-title" htmlFor="item-2">Тип букета</label>
					<div className="accordion-text">
						<p>{props.type.type}</p>
					</div>
				</div>

				<div className="accordion-item">
					<input className="accordion-input" type="checkbox" name="item" id="item-4"/>
					<label className="accordion-title" htmlFor="item-4">Скидки</label>
					<div className="accordion-text">
						<p>Действует накопительная система скидок!<br/>
							5, 10, 15%.</p>
					</div>
				</div>
				<div className="accordion-item">
					<input className="accordion-input" type="checkbox" name="item" id="item-6"/>
					<label className="accordion-title" htmlFor="item-6">Комплектация</label>
					<div className="accordion-text">
						<p>Упакуем букет в стильную фирменную коробку с крышкой, с полной защитой от перепадов погоды (жара, мороз, ветер или дождь - заказывайте букет в любую погоду)! В комплекте с букетом мы прилагаем инструкцию по уходу за букетом и средство для продления жизни цветов.</p>
					</div>
				</div>
				<div className="accordion-item">
					<input className="accordion-input" type="checkbox" name="item" id="item-7"/>
					<label className="accordion-title" htmlFor="item-7">Уход за букетом</label>
					<div className="accordion-text">
						<p>Свежие цветы доставляются в фирменной упаковке, которую необходимо снять при установке в вазу. Не срезайте ленту, связывающую букет для сохранения формы.

							Подрежьте стебли на 1см под острым углом, очистите их от нижних листьев и шипов. Повторяйте процедуру каждые 2-3 дня.

							Налейте в вазу холодную, чистую воду. Повторяйте процедуру каждые 2-3 дня.

							Для продления жизни цветам, рекомендуем поставить букет в прохладном месте, вдали от солнечных лучей.</p>
					</div>
				</div>
			</div>
	</div>
	);
}

export default Accordion;